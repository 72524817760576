import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css';

import { BrowserRouter , Switch ,Route} from 'react-router-dom';
import { Provider } from 'react-redux'
import {store,persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import Login from './screens/login/login'
import ForgetPassword from './screens/forgetPassword/forgetPassword'
import Splash from './screens/splash/splash'
import UserInfo from './screens/user info/user info'
import Users from './screens/user/Users'
import Admins from './screens/admin/admin'
import Search from './screens/search/search'
import Questions from './screens/question/question';
import QuestionsInfo from './screens/question info/question info';
import addQuestions from './screens/add question/add question';
import AddUser from './screens/add user/add user'
import setting from './screens/setting/setting';
import contactus from './screens/contactus/contactus';
import sendNotifs from './screens/send notifs/send notifs';
import Home from './screens/home/Home';
import About from './screens/about/about';
import Locations from './screens/location/location';
import LocationInfo from './screens/location info/location info';
import AddLocation from './screens/add location/add location';
import Categories from './screens/categories/categories';
import CategoryInfo from './screens/category info/category info';
import AddCategory from './screens/add category/add category';
import Deals from './screens/deals/deals';
import DealInfo from './screens/deal info/deal info';
import Properties from './screens/properties/properties';
import PropertyInfo from './screens/property info/property info';
import AddProperty from './screens/add property/add property';
import Compounds from './screens/compound/compound';
import CompoundInfo from './screens/compound info/compound info';
import AddCompound from './screens/add compound/add compound';
import Countries from './screens/countries/countries';
import CountryInfo from './screens/country info/country info'
import AddCountry from './screens/add country/add country'
import Employee from './screens/employee/employee';
import EmployeeInfo from './screens/employee info/employee info'
import AddEmployee from './screens/add employee/add employee'
import views from './screens/views/views';
import viewsInfo from './screens/views info/views info'
import Permissions from './screens/permission/permission';
import PermissionInfo from './screens/permission info/permission info'
import AddPermission from './screens/add permission/add permission'
import Supervisors from './screens/supervisor/supervisor';

import Clients from './screens/clients/clients';
import ClientInfo from './screens/client info/client info';
//import AddClient from './screens/add client/add client';
import Guidelines from './screens/guideline/guideline';
import GuidelineInfo from './screens/guideline info/guideline info'
import AddGuideline from './screens/add guideline/add guideline'

import Companies from './screens/company/company';
import CompanyInfo from './screens/company info/company info'
import AddCompany from './screens/add company/add company'
import Anoncements from './screens/anoncement/anoncement';
import AnoncementInfo from './screens/anoncement info/anoncement info'
import AddAnoncement from './screens/add anoncement/add anoncement'
import NewLaunches from './screens/newLaunches/newLaunches';
import NewLaunchInfo from './screens/newLaunch info/newLaunch info'
import AddNewLaunch from './screens/add newLaunch/add newLaunch'
import Prizes from './screens/prize/prize';
import PrizeInfo from './screens/prize info/prize info'
import AddPrize from './screens/add prize/add prize'
ReactDOM.render(
  
<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter >
      <Switch>
        <Route path={"/Login"} component={Login}/> 
        <Route exact path={"/"} component={Splash}/> 
        <Route path={"/forgetPassword"} component={ForgetPassword}/>
        <App path={'/CompanyInfo'} component={CompanyInfo}/> 
        <App path={'/Companies'} component={Companies}/> 
        <App path={'/AddCompany'} component={AddCompany}/> 
        <App path={'/PrizeInfo'} component={PrizeInfo}/> 
        <App path={'/Prizes'} component={Prizes}/> 
        <App path={'/AddPrize'} component={AddPrize}/> 
        <App path={'/AnoncementInfo'} component={AnoncementInfo}/> 
        <App path={'/Anoncements'} component={Anoncements}/> 
        <App path={'/AddAnoncement'} component={AddAnoncement}/> 
        <App path={'/NewLaunchInfo'} component={NewLaunchInfo}/> 
        <App path={'/NewLaunches'} component={NewLaunches}/> 
        <App path={'/AddNewLaunch'} component={AddNewLaunch}/> 

        <App path={'/GuidelineInfo'} component={GuidelineInfo}/> 
        <App path={'/Guidelines'} component={Guidelines}/> 
        <App path={'/AddGuideline'} component={AddGuideline}/> 
        <App path={'/views'} component={views}/>
        <App path={'/Supervisors'} component={Supervisors}/> 
        <App path={'/viewsInfo'} component={viewsInfo}/>
        <App path={'/PermissionInfo'} component={PermissionInfo}/> 
        <App path={'/Permissions'} component={Permissions}/> 
        <App path={'/AddPermission'} component={AddPermission}/> 
        <App path={'/employees'} component={Employee}/>
        <App path={'/employeeInfo'} component={EmployeeInfo}/>
        <App path={'/AddEmployee'} component={AddEmployee}/>
        <App path={'/Countries'} component={Countries}/>
        <App path={'/CountryInfo'} component={CountryInfo}/>
        <App path={'/AddCountry'} component={AddCountry}/>
        <App path={'/Properties'} component={Properties}/> 
        <App path={'/propertyInfo'} component={PropertyInfo}/> 
        <App path={'/addProperty'} component={AddProperty}/> 
        <App path={'/Compounds'} component={Compounds}/> 
        <App path={'/CompoundInfo'} component={CompoundInfo}/> 
        <App path={'/AddCompound'} component={AddCompound}/> 
        <App path={'/Deals'} component={Deals}/> 
        <App path={'/DealInfo'} component={DealInfo}/> 
        <App path={'/Clients'} component={Clients}/> 
        <App path={'/ClientInfo'} component={ClientInfo}/> 
        {/* <App path={'/addClient'} component={AddClient}/>  */}
        <App path={'/LocationInfo'} component={LocationInfo}/> 
        <App path={'/Locations'} component={Locations}/> 
        <App path={'/AddLocation'} component={AddLocation}/>
        <App path={'/Categories'} component={Categories}/> 
        <App path={'/CategoryInfo'} component={CategoryInfo}/> 
        <App path={'/AddCategory'} component={AddCategory}/>  
        <App path={'/Questions'} component={Questions}/>
        <App path={'/questionInfo'} component={QuestionsInfo}/>
        <App path={'/AddQuestion'} component={addQuestions}/>
        <App path={'/Search'} component={Search}/>
        <App path={'/about'} component={About}/>
        <App path={'/Home'} component={Home}/>
        <App path={'/users'} component={Users}/>
        <App path={'/Admins'} component={Admins}/>
        <App path={'/userInfo'} component={UserInfo}/>
        <App path={'/addUser'} component={AddUser}/>
        <App path={'/Setting'} component={setting}/>
        <App path={'/ContactUs'} component={contactus}/>
        <App path={'/SendNotifs'} component={sendNotifs}/>
        
      </Switch>
    </BrowserRouter>
  </PersistGate>
</Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
